import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DataService from '../../services/data.service';
import {
    Button, TextField,
    Table, TableHead, TableBody, TableContainer, TablePagination, TableCell, TableRow, DialogActions, DialogTitle,
    Drawer, IconButton, DialogContent, Dialog
} from '@material-ui/core';
import { X, Share, CheckCircle } from 'react-feather';

import Moment from 'react-moment';
import UploadPromos from './upload-promos';
import moment from "moment";

import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/airbnb.css";
const drawerWidth = 250;
const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0
    },
    drawerPaper: {
        width: drawerWidth
    },
    drawerContainer: {
        overflow: 'auto',
        background: "#fafafa",
        height: "100vh"
    },
    content: {
        flexGrow: 1
    }
}));

export default function TopUps() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date(new Date().getTime() + (1 * 24 * 60 * 60 * 1000)));
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [qry_pagination, setQryPage] = useState({ page: 0, size: 20 });
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const columns = [
        { id: 'id', label: 'ID' },
        { id: 'number', label: 'Mobile Number' },
        { id: 'amount', label: 'Amount' },
        { id: "topup_datetime", label: 'Date' },
        { id: "customer_ref", label: "Reference" },
        { id: "payment_status", label: '' }
    ];
    const _service = new DataService()
    const GetBankTopUps = () => {
        let a = moment(startDate).format("DD-MM-YYYY");
        let b = moment(endDate).format("DD-MM-YYYY");
        _service.GetBankTopUps(a, b, qry_pagination.page, qry_pagination.size).then((res) => {
            if (res.status === 200) {
                setRows(res.data.topups)
            }
        }).catch(() => {
        })
    }
    const handleChangePage = (event, newPage) => {
        setQryPage({ page: page + 1 })
        if (newPage == rows.length - 1) {
            //Show Table Loading
            GetBankTopUps()
        }
        else {
            setPage(newPage);
        }
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    useEffect(() => {
        GetBankTopUps()
    }, [])
    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <div className="row justify-content-center align-items-center">
                    <div className="col">
                        <div className="header">
                            <div className="header-body">
                                <div className="row align-items-end">
                                    <div className="col">
                                        <h6 className="header-pretitle text-secondary">Top Ups</h6>
                                        <h2 className="header-title">Disbursement History  </h2>
                                    </div>
                                    <div className="col-auto">
                                        <IconButton className="bg-grey" onClick={handleClickOpen}>
                                            <Share className="fe-sm" />
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row m-0 pb-3 justify-content-center align-items-center">
                    <div className="col-auto">2 Records</div>
                    <div className="col-auto">2 Batches</div>
                    <div className="col"></div>
                    <div className="col-auto mr-3">
                        <Flatpickr value={startDate} options={{ dateFormat: "d-M-Y", defaultDate: startDate }} onChange={date => { setStartDate(new Date(date)) }}
                            render={
                                ({ defaultValue, value, ...props }, ref) => {
                                    return <TextField variant="filled" className="w-auto input-sm" defaultValue={defaultValue} inputRef={ref} label="Start Date" />
                                }
                            } />
                    </div>
                    <div className="col-auto">
                        <Flatpickr value={endDate} onChange={date => { setEndDate(new Date(date)) }}
                            options={{ dateFormat: "d-M-Y", minDate: startDate }}
                            render={
                                ({ defaultValue, value, ...props }, ref) => {
                                    return <TextField variant="filled" className="w-auto input-sm" defaultValue={defaultValue} inputRef={ref}
                                        label="End Date" />
                                }
                            } />
                    </div>
                    <div className="">
                        <Button color="default" className="case-normal" color="primary" variant="contained" onClick={GetBankTopUps}>
                            Get Data
                         </Button>
                    </div>

                </div>
                <div className="card">
                    <div className="table-responsive">
                        <TableContainer className="table table-sm table-nowrap card-table">
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                        return (
                                                <TableRow hover tabIndex={-1} key={"row" + index}>
                                                    {
                                                        columns.map((column) => {
                                                            const value = row[column.id];
                                                            if (column.id === "payment_status") {
                                                                if (value === "PAID") {
                                                                    return (
                                                                        <TableCell key={column.id + "-" + index}>
                                                                            <button className="btn btn-sm fe-sm btn-rounded-circle badge-soft-danger">
                                                                                <CheckCircle />
                                                                            </button>
                                                                        </TableCell>
                                                                    );
                                                                }
                                                                else {
                                                                    return (
                                                                        <TableCell key={column.id + "-" + index}>
                                                                            <button className="btn btn-sm fe-sm btn-rounded-circle badge-soft-secondary">
                                                                                <X />
                                                                            </button>
                                                                        </TableCell>
                                                                    );

                                                                }
                                                            }
                                                            else if (column.id === "topup_datetime") {
                                                                return (
                                                                    <TableCell key={column.id + "-" + index}>
                                                                        <span className="badge badge-soft-secondary mr-2"><Moment format="hh:mm a">{value}</Moment></span>
                                                                        <Moment format="D MM YYYY">{value}</Moment>
                                                                    </TableCell>);
                                                            }
                                                            else if (column.id === "customer_ref") {
                                                                return (
                                                                    <TableCell key={column.id + "-" + index}>
                                                                        <span className="text-uppercase">{column.format && typeof value === 'number' ? column.format(value) : value}</span>
                                                                    </TableCell>
                                                                )
                                                            }
                                                            else {
                                                                return (
                                                                    <TableCell key={column.id + "-" + index}>
                                                                        {column.format && typeof value === 'number' ? column.format(value) : value}
                                                                    </TableCell>
                                                                );
                                                            }
                                                        })}
                                                </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            className="bg-grey"
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            labelRowsPerPage={''}
                            rowsPerPageOptions={[10, 25, 50]}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </div>
                </div>
            </div>
            <Dialog
                disableBackdropClick={true}
                fullWidth={true} maxWidth="md"
                open={open}
                aria-labelledby="responsive-dialog-title">
                <DialogTitle>
                    <div className="row justify-content-center align-items-center">
                        <div className="col">
                            Upload Top Ups
                        </div>
                        <div className="col-auto">
                            <IconButton><X onClick={handleClose} /></IconButton>
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <UploadPromos />
                </DialogContent>
                <DialogActions className="mt-4">
                    <Button className="pull-right text-sm mr-5" target="_blank" href="/static/files/PresetPromosTmp.xlsx" color="default">
                        <span className="small">Download Template</span>
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
