import React from 'react';
import Analytics from './analytics';
import Recipients from './recipients';
import Configuration from './configuration';
import Notifications from './notifications';
import Wallet from './wallet';
import TopUps from './promos/top-ups';
import uba_logo from '../assets/img/uba.svg'
import Promos from './promos/promos';
import Customers from './customers/index';
import isLoggedIn from '../helpers/is_logged_in';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { BarChart2, Tag, UploadCloud, Users, CreditCard, ShoppingBag, LogOut, Activity } from 'react-feather';
import { BrowserRouter, Switch, Route, NavLink, Redirect } from "react-router-dom";
const theme = createMuiTheme({
    typography: {
        fontSize: 14,
        fontFamily: [
            'Circular'
        ].join(','),
    },
    palette: {
        primary: {
            main: '#c53b08',
        }
    },
});
const Dashboard = () => {
    if (!isLoggedIn()) {
        return <Redirect to="/login" />
    }
    return (
        <MuiThemeProvider theme={theme}>
            <div className="App">
                <main id="main">
                    <BrowserRouter>
                        <nav className="navbar navbar-vertical fixed-left navbar-expand-md navbar-light" id="sidebar">
                            <div className="container-fluid">
                                <a href="/" className="navbar-brand">
                                     <img src={uba_logo} alt="logo" />
                                </a>
                                <div className="collapse navbar-collapse" id="sidebarCollapse">
                                    <h6 className="navbar-heading">
                                        Classic
                                     </h6>
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <NavLink className="nav-link fe-sm" to="/" activeClassName='active' exact={true} activeClassName='active'>
                                                <Activity /><div className="ml-2">Dashboard</div>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink className="nav-link fe-sm mr--3" to="/topups">
                                                <Tag /><div className="ml-2">Top Ups</div>
                                            </NavLink>
                                        </li>
                                    </ul>
                                    <hr className="navbar-divider my-3 line" />
                                    {/* <h6 className="navbar-heading">
                                        New
                                     </h6> 
                                    <ul className="navbar-nav mb-md-4">
                                         <li className="nav-item">
                                            <NavLink className="nav-link" to="/customers" activeClassName='active'>
                                                <Users /> Customers
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" to="/promos" activeClassName='active'>
                                                <Tag /> Promos
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" to="/recipients" activeClassName='active'>
                                                <ShoppingBag />Recipients
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" to="/wallet" activeClassName='active'>
                                                <CreditCard />Wallet
                                            </NavLink>
                                        </li> 
                                    </ul    <hr className="navbar-divider my-3" />>*/}
                                
                                    <h6 className="navbar-heading">
                                        Auth
                                     </h6>
                                    <ul className="navbar-nav mb-md-4">
                                        <li className="nav-item">
                                            <a className="nav-link fe-sm" href="/login">
                                                <LogOut /> <div className="ml-2">Log Out</div>
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="mt-auto"></div>
                                </div>
                            </div>
                        </nav>

                        <div className="main-content">
                            <div className="px-5">
                                <Switch>
                                    <Route path="/topups" component={TopUps} exact />
                                    <Route path="/" component={Analytics} />
                                    {/* <Route path="/customers" component={Customers} />
                                    <Route path="/recipients" component={Recipients} />
                                    <Route path="/promos" component={Promos} />
                                    <Route path="/wallet" component={Wallet} />
                                    <Route path="/notifications" component={Notifications} />
                                    <Route path="/configuration" component={Configuration} /> */}
                                </Switch>
                            </div>
                        </div>
                    </BrowserRouter>
                </main>
            </div>
        </MuiThemeProvider >)
}
export default Dashboard;
