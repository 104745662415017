import React, { useState, useEffect } from 'react';
import { Bar, Doughnut } from 'react-chartjs-2';
import GaugeChart from 'react-gauge-chart'
import DataService from '../services/data.service';
import Flatpickr from "react-flatpickr";
import { Button, TextField } from "@material-ui/core";
import "flatpickr/dist/themes/airbnb.css";
import moment from "moment";

export default function Analytics() {
    const [wallet, setWalletBalance] = useState(0);
    const [stats, setStats] = useState(0);
    const [startDate, setMinDate] = useState(new Date().getTime() - (7 * 24 * 60 * 60 * 1000));
    const [endDate, setMaxDate] = useState(new Date(new Date().getTime() + (2 * 24 * 60 * 60 * 1000)));
    const [BarDates, setBarDates] = useState(null);
    const [ArcValues, setArcValues] = useState({ total_spent: 0.1, wallet_balance: 0.14, isEmpty: false });
    const [BarValues, setBarValues] = useState(null);
    const _service = new DataService();
    const GetWalletBalance = () => {
        _service.GetWalletBalance().then((res) => {
            if (res.status === 200) {
                let a = res.data.total_spent;
                let b = res.data.wallet_balance;
                if (a == 0 && b <= 0) {
                    let d = ArcValues;
                    d.isEmpty = true;
                    setArcValues(d);
                    setTimeout(() => {
                    }, 1000);
                }
                else {
                    setArcValues({ total_spent: a, wallet_balance: b })
                    setWalletBalance(res.data)
                }
            }
        }).catch(error => {
        })
    }
    const GetStats = () => {
        _service.GetStats().then((res) => {
            if (res.status === 200) {
                setStats(res.data);
            }
        }).catch(error => {
        })
    }
    const GetTransactionHistory = () => {
        let a = moment(startDate).format("DD-MM-YYYY");
        let b = moment(endDate).format("DD-MM-YYYY");
        _service.GetTransactionHistory(a, b).then((res) => {
            if (res.status === 200) {
                let _BarDates = [];
                let _BarValues = [];
                res.data.topups.forEach(e => {
                    _BarDates.push(e.topup_datetime)
                    _BarValues.push(e.amount)
                });
                setBarDates(_BarDates);
                setBarValues(_BarValues);
            }
        }).catch(error => {
        })
    }
    // const onChange = (evt) => {
    //     setMinDate(moment(evt[0]).format("DD-MM-YYYY"));
    //     setMaxDate(moment(evt[1]).format("DD-MM-YYYY"));
    // }
    const LoadDashboard = () => {
        GetWalletBalance();
        GetStats();
        GetTransactionHistory();
    }
    useEffect(() => {
        LoadDashboard()
    }, [])

    const BarChart1 =
    {
        chart_data: {
            labels: BarDates,
            datasets: [
                {
                    label: "",
                    labels: BarDates,
                    data: BarValues,
                    backgroundColor: "#c53b08",
                    maxBarThickness: 20, barPercentage: 0.9, categoryPercentage: 0.8
                }
            ]
        },
        options: {
            scales: {
                yAxes: [{
                    ticks:
                    {
                        fontFamily: "Circular"
                    },
                    gridLines: {
                        display: true,
                        color: '#edf2f9',
                        drawBorder: false,
                        borderDash: [3]
                    },
                }],
                xAxes: [{
                    ticks: {
                        fontFamily: "Circular"
                    },
                    gridLines: { display: false },
                }],
            },
            legend: {
                display: false
            },
            cutoutPercentage: 80
        }
    }

    const DonutChart1 =
    {
        options: {
            cutoutPercentage: 85,
            tooltips: {
                callbacks: {
                    beforeLabel: function () {
                        return "NGN"
                    }
                },
                fontFamily: "Circular"
            },
            legend: {
                display: false
            },
        },
        data: {
            labels: [
                "Used",
                "Remaining",
            ],
            datasets: [
                {
                    data: [
                        ArcValues.total_spent,
                        ArcValues.wallet_balance
                    ],
                    backgroundColor: [
                        "#c53b08",
                        "#FA8072"
                    ],
                    borderWidth: 0
                }
            ],
            fontFamily: "Circular"
        }
    };
    return (
        <div>
            <div className="header">
                <div className="container-fluid">
                    <div className="header-body">
                        <div className="row align-items-center">
                            <div className="col">
                                <h6 className="header-pretitle text-secondary">Activity</h6>
                                <h1 className="header-title">Overview</h1>
                            </div>
                            <div className="col-auto mr-3">
                                <Flatpickr value={startDate}
                                    options={{ dateFormat: "d-M-Y", defaultDate: startDate }}
                                    onChange={date => {
                                        setMinDate(new Date(date))
                                    }}
                                    render={
                                        ({ defaultValue, value, ...props }, ref) => {
                                            return <TextField variant="filled" className="w-auto input-sm" defaultValue={defaultValue} inputRef={ref} label="Start Date" />
                                        }
                                    } />
                            </div>
                            <div className="col-auto mr-3">
                                <Flatpickr value={endDate}
                                    onChange={date => {
                                        setMaxDate(new Date(date))
                                    }}
                                    options={{ dateFormat: "d-M-Y", minDate: startDate }}
                                    render={
                                        ({ defaultValue, value, ...props }, ref) => {
                                            return <TextField variant="filled" className="w-auto input-sm" defaultValue={defaultValue} inputRef={ref}
                                                label="End Date" />
                                        }
                                    } />
                            </div>
                            <div className="col-auto">
                                <Button color="default" className="case-normal" color="primary" variant="contained" onClick={LoadDashboard}>
                                    Get Data
                             </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <h6 className="text-uppercase text-muted mb-2">
                                            Total Transaction Count
                                                    </h6>
                                        <span className="h2 mb-0">
                                            {stats.total_transaction_count}
                                        </span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <h6 className="text-uppercase text-muted mb-2">
                                            Total Transaction Value
                                                    </h6>
                                        <span className="h2 mb-0">
                                            {stats.total_transaction_value}
                                        </span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <h6 className="text-uppercase text-muted mb-2">
                                            Daily Transaction Count
                                                    </h6>
                                        <span className="h2 mb-0">
                                            {stats.daily_transaction_count}
                                        </span>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div className="col-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <h6 className="text-uppercase text-muted mb-2">
                                            Daily Transaction Value
                                                    </h6>
                                        <span className="h2 mb-0">
                                            {stats.daily_transaction_value}
                                        </span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-7">
                        <div className="card">
                            <div className="card-header border-0">
                                <h3 className="card-header-title">Transaction History</h3>
                            </div>
                            <div className="card-body py-4">
                                <div className="chart-container" style={{ position: "relative" }}>
                                    <Bar height={150} data={BarChart1.chart_data} options={BarChart1.options} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-header-title">Wallet Usage</h3>
                            </div>
                            <div className="card-body">
                                <div className="chart-container" style={{ position: "relative" }}>
                                    <Doughnut height={150} options={DonutChart1.options} data={DonutChart1.data} />
                                </div>
                                <div id="trafficChartLegend" className="chart-legend">
                                    <span className="chart-legend-item"><i className="chart-legend-indicator dark-red"></i>{ArcValues.total_spent} Used</span>
                                    <span className="chart-legend-item"><i className="chart-legend-indicator light-red"></i>{ArcValues.wallet_balance} Remaining</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
