import React from 'react';
import store from 'store';
import _ from 'lodash';
import auth_users from '../assets/data/data.json';
import uba_logo from '../assets/img/uba.svg'
import { FormGroup, TextField, createMuiTheme, Button, MuiThemeProvider, CircularProgress, InputAdornment, IconButton, OutlinedInput, InputLabel, FormControl } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import AuthService from '../services/auth.service';
const theme = createMuiTheme({
  typography: {
    fontSize: 16,
    fontFamily: [
      'Circular'
    ].join(','),
  },
  palette: {
    primary: {
      main: '#c53b08',
    }
  },
});
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      error: false,
      isLoading: false,
      showPassword: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._service = new AuthService();
  }
  onSubmit(e) {
    e.preventDefault();
    const { username, password } = this.state;
    this.setState({
      isLoading: true
    });
    if (auth_users.some(item => _.isEqual(item, {username: username,password:password}))) {
      this.setState({
        isLoading: true
      });
      this._service.Login(username, password).then((data) => {
        if (data === true) {
          store.set('loggedIn', true);
          this.props.history.push("/");
        }
      }).catch((err) => {
        this.setState({
          isLoading: false,
          error: true
        });
        return;
      })
    }
    else {
      this.setState({
        isLoading: false,
        error: true
      });
    }
  }
  handleChange(evt) {
    const value = evt.target.value;
    this.setState({
      [evt.target.name]: value
    });
  }
  render() {
    const handleClickShowPassword = () => {
      this.setState({
        showPassword: !this.state.showPassword
      })
    };

    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };
    return (
      <MuiThemeProvider theme={theme}>
        <div className="bg-auth">
          <div className="container">
            <div className="vh-100">
              <div className="row justify-content-center align-items-center h-100">
                <div className="col-12 col-md-5 col-xl-4 my-5">
                  <form onSubmit={this.onSubmit} className="mt-3 mb-4">
                    <FormGroup>
                      <div className="row">
                        <div className="col-12 text-center">
                          <img src={uba_logo} alt="logo" />
                        </div>
                        <div className="col-12 text-center">
                          <hr className="divider" />
                        </div>
                      </div>
                      <div className="form-group row text-center">
                        <div className="col">
                          <h3 className="mt-3 mb-5">Log In | Airtime Portal </h3>
                        </div>
                      </div>
                      {
                        this.state.error && <div className="alert alert-danger animated fadeIn">Invalid Credentials </div>
                      }
                    </FormGroup>
                    <FormGroup>
                      <div className="form-group row">
                        <div className="col">
                          <TextField name="username" label="Username" variant="outlined" value={this.state.username} onChange={this.handleChange} />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col">
                          <FormControl variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                            <OutlinedInput name="password" label="Password" type={this.state.showPassword ? 'text' : 'password'} value={this.state.password} onChange={this.handleChange}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end">
                                    {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                          </FormControl>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col">
                          <Button type="submit" color="primary" variant="contained" className="w-100 text-uppercase">
                            {this.state.isLoading && <CircularProgress size={24} color="secondary" />}
                            {!this.state.isLoading && 'Log In'}
                          </Button>
                        </div>
                      </div>
                    </FormGroup>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MuiThemeProvider>
    )
  }
}
export default Login;
