import React, {useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CreateCustomer from './create-customer';
import DataService from '../../services/data.service';
import { Drawer, Divider, List, ListItem, Button, Dialog, DialogContent,useMediaQuery, useTheme, 
Table, TableHead, TableBody, TableContainer, TablePagination, TableCell, TableRow,} from '@material-ui/core';
const drawerWidth = 350;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    }
}));
export default function Customers() {
    const classes = useStyles();
    const [currentPromo, setPromo] = useState({});
    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const columns = [
        { id: 'firstName', label: 'First Name'},
        { id: 'lastName', label: 'Last Name'},
        {
            id: 'number',
            label: 'Phone Number'
        },
        {
            id: 'email',
            label: 'Email'
        }
    ];
    const _service = new DataService()
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const GetCustomers=()=>{
        _service.GetCustomers().then((res)=>{
            console.log(res)
            if(res.status===200){
                setRows(res.data.customers)
            }
        }).catch(error => {
            console.log(error)
          })
    }
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleTableClick = (event, row) => {
        setPromo(row)
    };
    useEffect(()=>{
        GetCustomers()
        // do stuff here...
    }) 
    return (
        <div className={classes.root}>
            <div className="container">
                <div className="row justify-content-center align-items-center">
                    <div className="col">
                        <div className="header">
                            <div className="header-body">
                                <div className="row align-items-end">
                                    <div className="col">
                                        <h6 className="header-pretitle text-secondary">All Customers, Recipients & Non-Recipients</h6>
                                        <h1 className="header-title">Customer Repository</h1>
                                    </div>
                                    <div className="col-auto">
                                        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                                            Create Customer
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <TableContainer className="FixedTable">
                            <Table stickyHeader aria-label="sticky table" className="table-striped cs-style">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                        return (
                                            <TableRow hover
                                                onClick={(event) => handleTableClick(event, row)}
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={"row" + index}>
                                                {
                                                    columns.map((column) => {
                                                        const value = row[column.id];
                                                        if (column.id === "promo") {
                                                            return (
                                                                <TableCell key={column.id} align={column.align}>
                                                                    <span className="badge badge-soft-danger mr-4">
                                                                        {(index + 1) + (page * rowsPerPage)}
                                                                    </span>
                                                                    {value}
                                                                </TableCell>
                                                            );
                                                        }
                                                        else if (column.id === "conditions") {
                                                            return (<TableCell key={column.id} align={column.align}>
                                                                <span className="badge badge-soft-secondary">
                                                                    Conditions
                                                                </span>
                                                            </TableCell>
                                                            );
                                                        }
                                                        else {
                                                            return (
                                                                <TableCell key={column.id} align={column.align}>
                                                                    {column.format && typeof value === 'number' ? column.format(value) : value}
                                                                </TableCell>
                                                            );
                                                        }
                                                    })}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            className="cs-style"
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            labelRowsPerPage={''}
                            rowsPerPageOptions={[10]}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </div>
                </div>
            </div>
            <Drawer className={classes.drawer} variant="permanent" classes={{ paper: classes.drawerPaper, }} anchor="right">
                <div className="card shadow-none border-0 m-0">
                    <div className="card-body">
                        <div className="row align-items-center">
                            <div className="col-auto">
                                <h3 className="m-0">Recipient Summary</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <Divider />
                <div className="card shadow-none border-0 m-0">
                    <div className="card-body pb-0">
                        <div className="row align-items-center">
                            <div className="col-auto">
                                <div data-label=" " className="css-bar mb-0 css-bar-primary css-bar-20"></div>
                            </div>
                            <div className="col">
                                <h3 className="text-uppercase mb-2">18%</h3>
                                <span className="h4 mb-0 text-muted">Promo A</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card shadow-none border-0 m-0">
                    <div className="card-body pb-0">
                        <div className="row align-items-center">
                            <div className="col-auto">
                                <div data-label=" " className="css-bar mb-0 css-bar-warning css-bar-60"></div>
                            </div>
                            <div className="col">
                                <h3 className="text-uppercase mb-2">52%</h3>
                                <span className="h4 mb-0 text-muted">Promo B</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card shadow-none border-0 m-0">
                    <div className="card-body pb-0">
                        <div className="row align-items-center">
                            <div className="col-auto">
                                <div data-label=" " className="css-bar mb-0 css-bar-success css-bar-30"></div>
                            </div>
                            <div className="col">
                                <h3 className="text-uppercase mb-2">20%</h3>
                                <span className="h4 mb-0 text-muted">Promo C</span>
                            </div>
                        </div>
                    </div>
                </div>
                <Divider />
                <div className="card shadow-none border-0 m-0">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-sm-12">
                                <List>
                                    <ListItem>
                                        <div className="os-progress-bar primary w-100">
                                            <div className="bar-labels">
                                                <div className="bar-label-left"><span>0803..</span></div>
                                                <div className="bar-label-right"><span className="info">72/100</span></div>
                                            </div>
                                            <div className="bar-level-1" style={{ width: 100 + '%' }} >
                                                <div className="bar-level-2" style={{ width: 72 + '%' }} >
                                                    <div className="bar-level-3" style={{ width: 25 + '%' }} ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </ListItem>
                                    <ListItem>
                                        <div className="os-progress-bar primary w-100">
                                            <div className="bar-labels">
                                                <div className="bar-label-left"><span>0805...</span></div>
                                                <div className="bar-label-right"><span className="info">72</span></div>
                                            </div>
                                            <div className="bar-level-1" style={{ width: 100 + '%' }} >
                                                <div className="bar-level-2" style={{ width: 80 + '%' }} >
                                                    <div className="bar-level-3" style={{ width: 25 + '%' }} ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </ListItem>
                                    <ListItem>
                                        <div className="os-progress-bar primary w-100">
                                            <div className="bar-labels">
                                                <div className="bar-label-left"><span>0906..</span></div>
                                                <div className="bar-label-right"><span className="info">7,100</span></div>
                                            </div>
                                            <div className="bar-level-1" style={{ width: 100 + '%' }} >
                                                <div className="bar-level-2" style={{ width: 90 + '%' }} >
                                                    <div className="bar-level-3" style={{ width: 70 + '%' }} ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </ListItem>

                                    <ListItem>
                                        <div className="os-progress-bar primary w-100">
                                            <div className="bar-labels">
                                                <div className="bar-label-left"><span>0802...</span></div>
                                                <div className="bar-label-right"><span className="info">1002</span></div>
                                            </div>
                                            <div className="bar-level-1" style={{ width: 100 + '%' }} >
                                                <div className="bar-level-2" style={{ width: 72 + '%' }} >
                                                    <div className="bar-level-3" style={{ width: 25 + '%' }} ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </ListItem>
                                </List>
                            </div>
                        </div>
                    </div>
                </div>
            </Drawer>
            <Dialog 
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title">
                <DialogContent className="p-0"style={{width:'600px'}}>
                <CreateCustomer/>
                </DialogContent>
            </Dialog>
        </div>
    );
}
