class APP_CONFIG {
    PROXY ="https://cors-anywhere.herokuapp.com/";
    STAGING_URL="https://staging.seerbitapigateway.com/topup-promo/api/v1";
    //STAGING_URL = "https://ubatopup.herokuapp.com/api/v1";
    BASE_URL = this.STAGING_URL;
    LOGIN_URL = this.STAGING_URL;//+"/api/v1";
    FILE_URL = this.STAGING_URL;
    AUTH_URL = this.BASE_URL+"auth";
    GET_TRANX = this.BASE_URL+"transactions";
    DEFAULT_CREDS={"login_id":"admin@seerbit.com","password":"Centric_@123"}
}
export default APP_CONFIG;