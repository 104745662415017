import APP_CONFIG from './app.config';
import axios from 'axios';
import store from 'store';
class AuthService {
  constructor() {
    this.config = new APP_CONFIG();
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      // 'Cache-Control': 'max-age=3000',
     // 'Access-Control-Max-Age': 600
    }
  }
  async Login(username, password) {
    return axios.post(this.config.STAGING_URL+"/user/login", this.config.DEFAULT_CREDS).then(res => {
      store.set("AUTH_KEY", res.data.token)
      store.set("ID", res.data.user_payload.id)
      return true;
    });
  }

}
export default AuthService;