import React, { Component } from 'react';
import Login from './components/login';
import Dashboard from './components/dashboard';
import './assets/css/dash.css';
import {
  Switch,
  Route,
  BrowserRouter
} from "react-router-dom";

class App extends Component {
  render() {
    return (
        <BrowserRouter>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/" component={Dashboard} />
          </Switch>
        </BrowserRouter>
    )
  }
}
export default App;
