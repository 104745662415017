import APP_CONFIG from './app.config';
import React from 'react';
import axios from 'axios';
import store from 'store';
class DataService extends React.Component {
  constructor(props) {
    super(props);
    this.config = new APP_CONFIG();
    axios.interceptors.request.use(function (config) {
      let key = store.get('AUTH_KEY');
      if (key) {
        config.headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${key}`
        }
        return config;
      } else { store.clearAll(); return; }
    },
      function (err) {
        return Promise.reject(err);
      });
    axios.interceptors.response.use(function (response) {
        return response;
      }, function (error) {
        if (401 === error.response.status) {
            window.location = '/login';
        } else {
          return Promise.reject(error);
        }
      });
  }

  async SubmitBulkTopUp(data) {
    return axios.post(this.config.BASE_URL + "/topup/bank/" + store.get("ID"), data).then(res => {
      return res;
    }).catch(error => {
      console.log(error)
      return error;
    })
  }
  async CreateCustomer(obj) {
    let data = { customers: obj }
    return axios.post(this.config.BASE_URL + "/customer", data).then(res => {
      return res;
    }).catch(error => {
      return error;
    })
  }
  async GetCustomers() {
    return axios.get(this.config.BASE_URL + "/customer").then(res => {
      return res;
    }).catch(error => {
      console.log(error)
      return error;
    })
  }
  async GetBankTopUps(startDate, stopDate, page = 0, size = 10) {
    return axios.get(this.config.BASE_URL + "/topup/bank/" + store.get("ID") + "/start/" + startDate + "/stop/" + stopDate).then(res => {
      return res;
    }).catch(error => {
      console.log(error)
      return error;
    })
  }
  async GetWalletBalance() {
    return axios.get(this.config.BASE_URL + "/bank/" + store.get("ID") + "/wallet/balance").then(res => {
      return res;
    }).catch(error => {
      console.log(error)
      return error;
    })
  }
  async GetStats() {
    return axios.get(this.config.BASE_URL + "/topup/bank/" + store.get("ID") + "/analytics").then(res => {
      return res;
    }).catch(error => {
      console.log(error)
      return error;
    })
  }
  async GetTransactionHistory(minDate, maxDate) {
    return axios.get(this.config.BASE_URL + "/topup/bank/" + store.get("ID") + "/start/" + minDate + "/stop/" + maxDate).then(res => {
      return res;
    }).catch(error => {
      console.log(error)
      return error;
    })
  }
  GetTransactionHistory
  GetAuth() {
    return store.get("AUTH_KEY");
  }
  handleResponseError(response) {
    throw new Error("HTTP error, status = " + response.status);
  }
  handleError(error) {
    console.log(error.message);
  }

}
export default DataService;