import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Table, TableHead, TableBody, TableContainer, TablePagination, TableCell, TableRow,
    Drawer, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, IconButton
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { DownloadCloud } from 'react-feather';
import { Bar } from 'react-chartjs-2';

const drawerWidth = 400;
const columns = [
    { id: 'promo', label: 'Promo', minWidth: 170 },
    { id: 'last_execution', label: 'Last Call', minWidth: 100 },
    {
        id: 'conditions',
        label: 'Conditions'
    },
    {
        id: 'status',
        label: 'Status'
    }
];
const rows = [
    { promo: 'Midnight Bonaza 1', last_execution: '23-Mar-2020', trigger: 'Trigger', benchmark: 'Benchmark', status: 'Active', action: 'Action' },
    { promo: 'Midnight Bonaza 2', last_execution: '23-Mar-2020', trigger: 'Trigger', benchmark: 'Benchmark', status: 'Active', action: 'Action' },
    { promo: 'Midnight Bonaza 3', last_execution: '23-Mar-2020', trigger: 'Trigger', benchmark: 'Benchmark', status: 'Active', action: 'Action' },
    { promo: 'Midnight Bonaza 4', last_execution: '23-Mar-2020', trigger: 'Trigger', benchmark: 'Benchmark', status: 'Active', action: 'Action' },
    { promo: 'Midnight Bonaza 5', last_execution: '23-Mar-2020', trigger: 'Trigger', benchmark: 'Benchmark', status: 'Active', action: 'Action' },
    { promo: 'Midnight Bonaza 6', last_execution: '23-Mar-2020', trigger: 'Trigger', benchmark: 'Benchmark', status: 'Active', action: 'Action' },
    { promo: 'Midnight Bonaza 7', last_execution: '23-Mar-2020', trigger: 'Trigger', benchmark: 'Benchmark', status: 'Active', action: 'Action' },
    { promo: 'Midnight Bonaza 8', last_execution: '23-Mar-2020', trigger: 'Trigger', benchmark: 'Benchmark', status: 'Active', action: 'Action' },
    { promo: 'Midnight Bonaza 9', last_execution: '23-Mar-2020', trigger: 'Trigger', benchmark: 'Benchmark', status: 'Active', action: 'Action' },
    { promo: 'Midnight Bonaza 10', last_execution: '23-Mar-2020', trigger: 'Trigger', benchmark: 'Benchmark', status: 'Active', action: 'Action' },
    { promo: 'Midnight Bonaza', last_execution: '23-Mar-2020', trigger: 'Trigger', benchmark: 'Benchmark', status: 'Active', action: 'Action' },
    { promo: 'Midnight Bonaza', last_execution: '23-Mar-2020', trigger: 'Trigger', benchmark: 'Benchmark', status: 'Active', action: 'Action' },
    { promo: 'Midnight Bonaza', last_execution: '23-Mar-2020', trigger: 'Trigger', benchmark: 'Benchmark', status: 'Active', action: 'Action' },
    { promo: 'Midnight Bonaza', last_execution: '23-Mar-2020', trigger: 'Trigger', benchmark: 'Benchmark', status: 'Active', action: 'Action' },
    { promo: 'Midnight Bonaza', last_execution: '23-Mar-2020', trigger: 'Trigger', benchmark: 'Benchmark', status: 'Active', action: 'Action' },
    { promo: 'Midnight Bonaza', last_execution: '23-Mar-2020', trigger: 'Trigger', benchmark: 'Benchmark', status: 'Active', action: 'Action' },
    { promo: 'Midnight Bonaza', last_execution: '23-Mar-2020', trigger: 'Trigger', benchmark: 'Benchmark', status: 'Active', action: 'Action' },
    { promo: 'Midnight Bonaza', last_execution: '23-Mar-2020', trigger: 'Trigger', benchmark: 'Benchmark', status: 'Active', action: 'Action' }
];
const columns_two = [
    { id: 'call_date', label: 'Call Date' },
    { id: 'recipient_count', label: '' },
    {
        id: 'action',
        label: ''
    }
];
const rows_two = [
    { call_date: '11-Apr-2020', recipient_count: '12 recipients', action: 'Action' },
    { call_date: '11-Apr-2020', recipient_count: '12 recipients', action: 'Action' },
    { call_date: '11-Apr-2020', recipient_count: '12 recipients', action: 'Action' },
    { call_date: '11-Apr-2020', recipient_count: '12 recipients', action: 'Action' },
    { call_date: '11-Apr-2020', recipient_count: '12 recipients', action: 'Action' },
    { call_date: '11-Apr-2020', recipient_count: '12 recipients', action: 'Action' }
];
const Promo_BarChart =
{
    chart_data: {
        labels: ["Oct 1",
            "Oct 2",
            "Oct 3",
            "Oct 4",
            "Oct 5",
            "Oct 6",
            "Oct 7",
            "Oct 8",
            "Oct 9",
            "Oct 10",
            "Oct 11",
            "Oct 12"],
        datasets: [
            {
                label: "2020",
                data: [25, 20, 30, 22, 17, 10, 18, 26, 28, 26, 20, 32],
                backgroundColor: "#2D7BE5",
                maxBarThickness: 10, barPercentage: 0.9, categoryPercentage: 0.8
            },
            {
                label: "2019", data: [15, 10, 20, 12, 7, 0, 8, 16, 18, 16, 10, 22], backgroundColor: "#d2ddec", barThickness: 10
            }
        ]
    },
    options: {
        barValueSpacing: 30,
        scales: {
            yAxes: [{
                ticks:
                {
                    fontFamily: "Circular"
                },
                gridLines: {
                    display: true,
                    color: '#edf2f9',
                    drawBorder: false,
                    borderDash: [3]
                },
            }],
            xAxes: [{
                ticks: {
                    fontFamily: "Circular"
                },
                gridLines: { display: false },
            }],
        }
    }
};
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerContainer: {
        overflow: 'auto',
    },
    content: {
        flexGrow: 1
    }
}));

export default function Promos() {
    const classes = useStyles();
    const [currentPromo, setPromo] = useState({});
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(4);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleTableClick = (event, row) => {
        setPromo(row)
    };
    
    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <div className="row justify-content-center align-items-center">
                    <div className="col">
                        <div className="header">
                            <div className="header-body">
                                <div className="row align-items-end">
                                    <div className="col">
                                        <h6 className="header-pretitle text-secondary">Promo Categories, Triggers & Benchmarks</h6>
                                        <h1 className="header-title">Promotions</h1>
                                    </div>
                                    <div className="col-auto">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <TableContainer className="FixedTable">
                            <Table stickyHeader aria-label="sticky table" className="table-striped cs-style">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                        return (
                                            <TableRow hover
                                                onClick={(event) => handleTableClick(event, row)}
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={"row" + index}>
                                                {
                                                    columns.map((column) => {
                                                        const value = row[column.id];
                                                        if (column.id === "promo") {
                                                            return (
                                                                <TableCell key={column.id} align={column.align}>
                                                                    <span className="badge badge-soft-danger mr-4">
                                                                        {(index + 1) + (page * rowsPerPage)}
                                                                    </span>
                                                                    {value}
                                                                </TableCell>
                                                            );
                                                        }
                                                        else if (column.id === "conditions") {
                                                            return (<TableCell key={column.id} align={column.align}>
                                                                <span className="badge badge-soft-secondary">
                                                                    Conditions
                                                                </span>
                                                            </TableCell>
                                                            );
                                                        }
                                                        else {
                                                            return (
                                                                <TableCell key={column.id} align={column.align}>
                                                                    {column.format && typeof value === 'number' ? column.format(value) : value}
                                                                </TableCell>
                                                            );
                                                        }
                                                    })}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            className="cs-style"
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            labelRowsPerPage={''}
                            rowsPerPageOptions={[4]}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <hr />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h3>Disbursement History</h3>
                    </div>
                    <div className="col-12">
                        <Bar height={300} data={Promo_BarChart.chart_data} options={Promo_BarChart.options} />
                    </div>
                </div>

            </div>
            <Drawer className={classes.drawer} variant="permanent" classes={{ paper: classes.drawerPaper }} anchor="right">
                <div className="bg-grey">
                    <div className="row h-100 py-5 m-0 justify-content-center align-items-center">
                        <div className="col">
                            <div>Viewing</div>
                            <h1 className="m-0">{currentPromo.promo}</h1>
                        </div>
                    </div>
                </div>
                <div className={classes.drawerContainer}>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    <p className="text-muted">Last Call</p>
                                    <h5>23-Mar-2020</h5>
                                </div>
                                <div className="col">
                                    <p className="text-muted">Created Date</p>
                                    <h5>23-Mar-2020</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <ExpansionPanel elevation={0}>{/* square={true} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}> */}
                                <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                                    <h3>Conditions</h3>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <div className="row m-0">
                                        <div className="col-12">

                                            <p>
                                                <u>Triggers</u>
                                            </p>
                                        </div>
                                        <div className="col-12">

                                            <p>
                                                <u>Benchmarks</u>
                                            </p>
                                        </div>
                                    </div>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel elevation={0}>
                                {/* square={true} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}> */}
                                <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                                    <h3>Logs</h3>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <div className="row w-100 m-0">
                                        <div className="col-12">
                                            <TableContainer className="FixedTable">
                                                <Table size="small" stickyHeader aria-label="sticky table v-small" className="table-striped cs-style">
                                                    <TableHead>
                                                        <TableRow>
                                                            {columns_two.map((column) => (
                                                                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                                                    {column.label}
                                                                </TableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {rows_two.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                                            return (
                                                                <TableRow hover
                                                                    tabIndex={-1}
                                                                    key={"row_two" + index}>
                                                                    {
                                                                        columns_two.map((column) => {
                                                                            const value = row[column.id];
                                                                            if (column.id === "action") {
                                                                                return (
                                                                                    <TableCell key={column.id} align={column.align}>
                                                                                        <IconButton className="bg-soft-primary">
                                                                                            <DownloadCloud className="fe-sm" />
                                                                                        </IconButton>
                                                                                    </TableCell>
                                                                                );
                                                                            } else {
                                                                                return (
                                                                                    <TableCell key={column.id} align={column.align}>
                                                                                        {column.format && typeof value === 'number' ? column.format(value) : value}
                                                                                    </TableCell>
                                                                                );
                                                                            }
                                                                        })}
                                                                </TableRow>
                                                            );
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <TablePagination
                                                className="cs-style"
                                                component="div"
                                                count={rows.length}
                                                rowsPerPage={rowsPerPage}
                                                labelRowsPerPage={''}
                                                rowsPerPageOptions={[4]}
                                                page={page}
                                                onChangePage={handleChangePage}
                                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                            />
                                        </div>
                                    </div>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </div>
                    </div>
                </div>
            </Drawer>
        </div>
    );
}
