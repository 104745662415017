import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { FormGroup, TextField, Button, Card, CardContent } from '@material-ui/core';
import DataService from '../../services/data.service';
import 'filepond/dist/filepond.min.css';
import { FilePond, registerPlugin } from 'react-filepond';
import { OutTable, ExcelRenderer } from 'react-excel-renderer';
import { Link } from "react-router-dom";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import XLSX from 'xlsx';
registerPlugin(FilePondPluginFileValidateType);


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

function CreateCustomer() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default" elevation={0}>
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Create New Customer" {...a11yProps(0)} />
          <Tab label="Bulk Customer Creation" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <SingleCreate />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <BulkCreate />
      </TabPanel>
    </div>
  );
}
class SingleCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: {
        mobile_number: '',
        last_name: '',
        first_name: '',
        email: '',
        customer_ref: ''
      }
    };
    this.handleCustomerChange = this.handleCustomerChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._service = new DataService();
  }
  onSubmit(e) {
    e.preventDefault();
    const { customer } = this.state;
    this._service.CreateCustomer(customer).then((data) => {
      if (data.status === 200) {
        console.log(data.data)
      }
    }).catch((err) => {
      return;
    })
  }
  handleChange_ = evt => {
    evt.persist();
    const value = evt.target.value;
    const name = evt.target.name;
    var someProperty = { ...this.state[name] }
    console.log(someProperty)
    someProperty = value;
    this.setState({ someProperty })
  }
  handleCustomerChange = evt => {
    evt.persist();
    const value = evt.target.value;
    const name = evt.target.name;
    this.setState({ customer: { ...this.state.customer, [name]: value } });
  }
  handleChange = evt => {
    evt.persist();
    const value = evt.target.value;
    this.setState({ name: value });
  }
  render() {
    return (
      <form onSubmit={this.onSubmit} className="mt-3 mb-4">
        <FormGroup>
          <div className="form-group row">
            <div className="col">
              <TextField name="first_name" label="Last Name" variant="outlined"
                value={this.state.customer.first_name} onChange={this.handleCustomerChange} />
            </div>
          </div>
          <div className="form-group row">
            <div className="col">
              <TextField name="last_name" label="First Name" variant="outlined" value={this.state.customer.last_name} onChange={this.handleCustomerChange} />
            </div>
          </div>
          <div className="form-group row">
            <div className="col">
              <TextField name="mobile_number" label="Mobile Number" variant="outlined" value={this.state.customer.mobile_number} onChange={this.handleCustomerChange} />
            </div>
          </div>
          <div className="form-group row">
            <div className="col">
              <TextField name="email" label="Email" variant="outlined" value={this.state.customer.email} onChange={this.handleCustomerChange} />
            </div>
          </div>
          <div className="form-group row">
            <div className="col">
              <Button type="submit" color="primary" variant="contained" className="w-100 text-uppercase">Create Customer</Button>
            </div>
          </div>
        </FormGroup>
      </form>
    )
  }
}

class BulkCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state =
    {
      files: [],
      cols: [],
      rows: [],
      bulkData: [],
      isComplete: false, isFileAdded: false
    };
    this._service = new DataService()
  }
  PreviewFile() {
    let fileObj = this.state.files[0];
    if (typeof (fileObj) == "object") {
      ExcelRenderer(fileObj, (err, resp) => {
        if (err) {
          this.setState({ isFileAdded: true })
        }
        else {
          this.setState({ bulkData: resp.rows });
          this.setState({ isFileAdded: true })
          if (resp.rows.length > 10) {
            resp.rows.splice(11);
            this.setState({
              cols: resp.cols,
              rows: resp.rows
            });
          } else {
            this.setState({
              cols: resp.cols,
              rows: resp.rows
            });
          }
        }
      });


    }
  }
  BulkCreate() {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws);
      /* Update state */
      this._service.CreateCustomer(data).then((res) => {
        if (res.status === 200) {
          this.setState({
            isComplete: true
          });
        }
      }).catch((err) => {
        console.log(err)
      })

    };
    if (rABS) {
      reader.readAsBinaryString(this.state.files[0]);
    } else {
      reader.readAsArrayBuffer(this.state.files[0]);
    };
  }
  render() {
    return (
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col">
            <div className={this.state.isComplete ? 'hidden container' : 'container'}>
              <div className="row justify-content-center">
                <div className="col-9">
                  <FilePond instantUpload={false} server="/api"
                    acceptedFileTypes={['.csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']}
                    onremovefile={fileItem => {
                      this.setState({ isFileAdded: false })
                      this.setState({bulkData:[]})
                    }}
                    onupdatefiles={(fileItems) => {
                      this.setState({
                        files: fileItems.map(fileItem => fileItem.file)
                      });
                      this.PreviewFile()
                    }} />
                </div>
              </div>
              <div className="row justify-content-center align-items-center mt-4">
                <div className="col-12">
                  <hr />
                </div>
              </div>
              {this.state.isFileAdded ?
                <div className="row justify-content-center align-items-center mt-4">
                  <div className="col">
                    <h2 className="m-0">Preview </h2>
                  </div>
                  <div className="col-auto">
                    <Button onClick={() => this.BulkCreate()} variant="contained" color="primary">Create Customers</Button>
                  </div>
                  <div className="col-12">
                    <hr />
                  </div>
                </div>
                : "Placeholder SVG"}
              <div className="row">
                <div className="col-12 excel-div">
                  <OutTable data={this.state.rows} columns={this.state.cols} tableClassName="ExcelTable2007" tableHeaderRowClass="heading" />
                </div>
              </div>
            </div>
            <div className={!this.state.isComplete ? 'container animated fadeIn hidden' : 'container animated fadeIn'}>
              <div className="row justify-content-center">
                <div className="col-8">
                  <Card className="bg-auth text-center">
                    <CardContent className="p-4">
                      <h1 className="text-center mt-4">Customers Imported</h1>
                      <Link to="/customers">
                        <Button variant="contained" color="primary" size="small">View Customers</Button>
                      </Link>
                    </CardContent>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default CreateCustomer;
