import React from 'react';
import XLSX from 'xlsx';
import loader_svg from '../../assets/img/loader.svg';
import {
    Button, Card, CardContent, CircularProgress,
    Table, TableHead, TableBody, TableContainer, TablePagination, TableCell, TableRow
} from '@material-ui/core';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import { OutTable, ExcelRenderer } from 'react-excel-renderer';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { Link } from "react-router-dom";
import DataService from "../../services/data.service";
registerPlugin(FilePondPluginFileValidateType);
class UploadPromos extends React.Component {
    constructor(props) {
        super(props);
        this.state =
        {
            files: [],
            cols: [],
            rows: [],
            isComplete: false, isFileAdded: false, isLoading: false
        };
        this._service = new DataService()
    }

    PreviewFile() {
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        reader.onload = (e) => {
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws, {
                header: ["mobile_number", "amount"],range:1
            });
            console.log(data)
            this.setState({ rows: data })
            this.setState({ isFileAdded: true })
        };
        if (rABS) {
            reader.readAsBinaryString(this.state.files[0]);
        } else {
            reader.readAsArrayBuffer(this.state.files[0]);
        };
    }
    BulkCreate() {
        this.setState({
            isLoading: true
        });
        this._service.SubmitBulkTopUp(this.state.rows).then((res) => {
            if (res.status === 200) {
                this.setState({
                    isComplete: true
                });
            }
        }).catch((err) => {
            console.log(err)
            this.setState({
                isLoading: false
            });
        })
    }
    GetTopUps(){

    }
    render() {
        return (
            <div className="container">
                <div className="row justify-content-center align-items-center">
                    <div className="col">
                        <div className={this.state.isComplete ? 'hidden container' : 'container'}>
                            <div className="row justify-content-center align-items-center">
                                <div className="col-6">
                                    <FilePond instantUpload={false}
                                        acceptedFileTypes={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']}
                                        onremovefile={fileItem => {
                                            this.setState({ rows: [] })
                                            this.setState({ isFileAdded: false })
                                            this.setState({ bulkData: [] })
                                        }}
                                        onupdatefiles={(fileItems) => {
                                            this.setState({
                                                files: fileItems.map(fileItem => fileItem.file)
                                            });
                                            if (fileItems.length > 0) {
                                                this.PreviewFile()
                                            }
                                        }} />

                                    {this.state.isFileAdded ?
                                        <Button onClick={() => { if (!this.state.isLoading) { this.BulkCreate() } }} variant="contained" color="primary" className="w-100 btn-lg text-uppercase">
                                            {this.state.isLoading && <CircularProgress size={24} color="secondary" />}
                                            {!this.state.isLoading && 'Import Data'}
                                        </Button>
                                        : ''
                                    }</div>
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-12">
                                            <TableContainer className="FixedTable">
                                                <Table stickyHeader aria-label="sticky table" className="table-striped cs-style">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Mobile Number</TableCell>
                                                            <TableCell>Amount</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.rows.map((row) => (
                                                            <TableRow key={row.mobile_number}>
                                                                <TableCell>{row.mobile_number}</TableCell>
                                                                <TableCell>{row.amount}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                        {this.state.isFileAdded ? '' :
                                                         <TableRow>
                                                            <TableCell className="p-0" colSpan={2}>
                                                                <img className="mt-2" src={loader_svg} alt="" />
                                                            </TableCell>
                                                        </TableRow>}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={!this.state.isComplete ? 'container animated fadeIn hidden' : 'container animated fadeIn'}>
                            <div className="row justify-content-center">
                                <div className="col-8">
                                    <Card className="bg-auth text-center">
                                        <CardContent className="p-4">
                                            <h1 className="text-center mt-4">Promos Imported</h1>
                                            <Link to="/promos">
                                                <Button variant="contained" color="primary" size="small">View Promos</Button>
                                            </Link>
                                        </CardContent>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default UploadPromos;
